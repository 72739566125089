.status-page-container {
    position: relative;
    width: 100%;
    height: 100%;

    .status-info-wrap {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon-loading {
            font-size: 45px;
            margin-bottom: 24px;
        }

        .status-title {
            margin: 24px 0;
        }
    }
}