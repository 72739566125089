@import url('//at.alicdn.com/t/c/font_2824546_0bvp1hoye3c5.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* 后续通过JS操作根节点变量实现主题色切换 */
:root {
  /* 主题色 */
  --theme-color: #1677ff;
  /* 主题背景色 */
  --theme-bg-color: #1677ff;
  /* 主题文字色 */
  --theme-font-color: #1677ff;
  /* 主题黑色 */
  --theme-block-color: #363636;
  /* 主题灰色 */
  --theme-gray-color: #d6d6d6;
  /* 主题默认圆角数 */
  --theme-radius: 6px;
}


ul,
dl,
dt,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  border: none;
  outline: none;
}

#root {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  .console-layout-container {
    height: 100%;
  }
}

/** 
* 公共样式 
**/
/* 全局 Loading icon */
.icon-loading {
  animation: loading_rotate 1.2s linear infinite;
}

@keyframes loading_rotate {
  from {
    transform: rotate(0) scale(1.3);
  }

  to {
    transform: rotate(360deg) scale(1.3);
  }

}