.register-page-container {
    width: 328px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    .register-page-title {
        max-width: 380px;
        margin: 5px auto 13px;
        word-break: break-word;
        font-size: 24px;
        line-height: 1.5;
        font-weight: 600;
        text-align: center;
        color: var(--theme-color);
    }

    .qrcode-img {
        width: 328px;
        text-align: center;
        border: 1px solid #ddd;

        .qrcode {
            display: block;
            width: 100%;
        }
    }


    .register-btns-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .register-other-btn {
            margin-top: 12px;
            font-size: 12px;
            color: var(--theme-color);
            cursor: pointer;
        }
    }
}