.login-page-container {
    width: 328px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    .login-page-title {
        max-width: 380px;
        margin: 5px auto 13px;
        word-break: break-word;
        font-size: 24px;
        line-height: 1.5;
        font-weight: 600;
        color: var(--theme-color);
    }

    .login-form-wrap {
        width: 100%;

        .login-form-item {
            // height: 44px;
            // border: 1px solid var(--theme-gray-color);
            // margin-bottom: -1px;
            position: relative;

            .ant-input-affix-wrapper {
                padding: 0;
                // border: none;

                .ant-input-prefix {
                    margin-left: 12px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 100;
                    width: 44px;
                    display: flex;
                    justify-content: center;
                }

                .ant-input-suffix {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 100;
                    width: 44px;
                    display: flex;
                    justify-content: center;

                    .iconfont {
                        cursor: pointer;
                    }
                }
            }

            .ant-input {
                height: 42px;
                // border: none;
                padding: 0 44px;
                box-sizing: border-box;

                &:focus {
                    outline: 1px solid var(--theme-color);
                    position: relative;
                    z-index: 10;

                }
            }


            .icon-solid-right {
                cursor: pointer;
                margin: auto;
                z-index: 10;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                text-indent: 2px;
                color: var(--theme-color);
                border-radius: 50%;
                border: 1px solid var(--theme-color);
            }

            // 用户名表单设置圆角
            &:first-child {
                border-top-left-radius: var(--theme-radius);
                border-top-right-radius: var(--theme-radius);

                .ant-input {
                    border-top-left-radius: var(--theme-radius);
                    border-top-right-radius: var(--theme-radius);
                }
            }

            // 密码表单设置圆角
            &:last-child {
                border-bottom-left-radius: var(--theme-radius);
                border-bottom-right-radius: var(--theme-radius);

                .ant-input {
                    border-bottom-left-radius: var(--theme-radius);
                    border-bottom-right-radius: var(--theme-radius);
                }
            }

            &.active-form-item {
                border-bottom-left-radius: var(--theme-radius);
                border-bottom-right-radius: var(--theme-radius);

                .ant-input {
                    border-bottom-left-radius: var(--theme-radius);
                    border-bottom-right-radius: var(--theme-radius);
                }
            }

        }
    }


    .login-btn {
        margin-top: 20px;
        height: 42px;
    }

    .login-btns-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 12px;
        font-size: 12px;
        color: var(--theme-color);

        .login-other-btn {
            color: var(--theme-color);
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &:active {
                transform: scale(1.2);
            }
        }
    }
}