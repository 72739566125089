.reset-pwd-page-container {
    width: 328px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    .reset-pwd-page-title {
        max-width: 380px;
        margin: 5px auto 13px;
        word-break: break-word;
        font-size: 24px;
        line-height: 1.5;
        font-weight: 600;
        text-align: center;
        color: var(--theme-color);
    }

    .reset-pwd-page-form-wrap {
        width: 100%;

        .login-form-item {
            height: 44px;
            border: 1px solid var(--theme-gray-color);
            border-radius: var(--theme-radius);
            position: relative;

            .ant-input-affix-wrapper {
                padding: 0;
                border: none;

                .ant-input-prefix {
                    margin-left: 12px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 100;
                    width: 44px;
                    display: flex;
                    justify-content: center;
                }

                .ant-input-suffix {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 100;
                    min-width: 44px;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            // 手机号右侧padding加大，防止验证码文案重叠
            .phone-input {
                .ant-input {
                    padding-right: 80px;
                }
            }

            .ant-input {
                height: 42px;
                border: none;
                padding: 0 44px;
                border-radius: var(--theme-radius);
                box-sizing: border-box;

                &:focus {
                    outline: 1px solid var(--theme-color);
                    position: relative;
                    z-index: 10;

                }
            }

        }
    }

    .reset-pwd-btns-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .reset-pwd-btn {
            width: 100%;
            height: 44px;
            line-height: 44px;
            padding: 0;
        }

        .reset-pwd-other-btn {
            margin-top: 12px;
            font-size: 12px;
            color: var(--theme-color);
            cursor: pointer;
        }
    }
}