.login-register-container {
    position: relative;
    width: 100%;
    height: 100%;

    // 倒计时按钮
    .verify-code-btn {
        margin: 0 10px;
        display: block;
        cursor: text;
        font-size: 12px;
        color: #6d6d6d;

        &.btn-style {
            font-size: 12px;
            cursor: pointer;
            background: var(--theme-color);
            padding: 4px 8px;
            color: #fff;
            border-radius: var(--theme-radius);
        }

        &.disabled-verify-btn {
            filter: grayscale(1);
            cursor: no-drop;
        }
    }
}