.user-center-container {

    .miniapp-qrcode-wrap {
        position: relative;
        width: 260px;
        height: 260px;
        margin: 60px auto 30px;
        background-color: #fff;
        padding: 24px;
        border-radius: 50%;
        box-shadow: 0 0 4px 2px #ddd;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            &::after {
                height: 46px;
            }
        }

        &:active {
            &::after {
                transform: scale(1.2);
            }
        }

        &::after {
            content: "点击下载";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
            background: rgba(0, 0, 0, .6);
            width: 100%;
            height: 0;
            line-height: 46px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            transition: all .3s;
        }



        .miniapp-qrcode {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
        }
    }

    .miniapp-info-wrap {
        display: block;
        width: 500px;
        margin: 0 auto;
        box-sizing: border-box;
        text-align: center;
        padding: 24px 40px;
        background-color: #fff;
        box-shadow: 0 0 4px 2px #ddd;
        border-radius: 24px;

        .miniapp-info-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 42px;
            flex: 1;
            font-size: 16px;
            padding: 24px 32px;
            box-sizing: border-box;

            .info-row-key {
                width: 120px;
                text-align: right;
            }

            .info-row-val {
                text-align: left;
                flex: 1;
            }
        }
    }
}