.search-wrap {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: baseline;

    .search-form-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;

        .search-form-item {
            flex: 1;
            max-width: 33.33%;
            min-width: 33.33%;
            box-sizing: border-box;
            padding-right: 12px;
            margin-bottom: 12px;

            // 单选框样式覆盖
            .ant-radio-group {
                display: flex;
                flex-direction: row;

                .ant-radio-button-wrapper {
                    flex: 1;
                    text-align: center;
                }
            }

            // 日期选择宽度修改
            .ant-picker {
                width: 100%;
            }
        }
    }

    .search-btns-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        top: 32px;

        .search-confirm-btn {
            margin-right: 12px;
        }

        .search-reset-btn {}

        .search-more-btn {
            // display: flex;
            // flex-direction: row;
            // align-items: center;
            // min-height: 140px;
            // position: relative;
            // top: 46px;
            // justify-content: space-between;
        }
    }
}