.contract-files-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .contract-card {
        width: 48%;
        padding: 12px;
        border-radius: 12px;
        margin-bottom: 24px;
        box-shadow: 0 0 6px 0 #666;
        height: 120px;
        overflow: hidden;

        .contract-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;

            .contract-title {
                font-size: 16px;
                white-space: pre-wrap;
                word-break: break-all;
                overflow: hidden;
                color: #333;
                font-weight: 600;
            }

            .icon-maobi {
                font-size: 22px;
                width: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                border-radius: 6px;
                cursor: pointer;
                color: #666;

                &:hover {
                    background: #ddd;
                    color: #333;
                }
            }
        }

        .contract-desc {
            font-size: 12px;
            color: #666;
        }
    }
}

// markdown 编辑器
.markdown-editor-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background-color: #fff;
}