.console-layout-content {
    display: flex;
    flex-direction: row;

    .content-sider-wrap {
        display: flex;
        flex-direction: column;

        .content-sider-menu {
            flex: 1;
        }

        .content-sider-switch {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #fff;
            position: relative;
            height: 40px;
            background-color: #001529;

            .switch-text,
            .switch-icon {
                position: relative;
                z-index: 10;
            }

            &:hover {
                &::after {
                    content: "";
                    width: 90%;
                    height: 92%;
                    position: absolute;
                    z-index: 0;
                    background: var(--theme-color);
                    border-radius: 8px;
                }
            }


        }
    }

    .content-main-wrap {
        overflow-y: auto;
        padding: 24px;
        box-sizing: border-box;
    }

    .contact-content {
        position: fixed;
        right: 30px;
        bottom: 10%;
        background-color: #fff;
        z-index: 999;
        border-radius: 20px;

        .code-content img {
            width: 80px;
            height: 80px;
        }
    }
}