
    .modal-user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        // background-color: #ddd;
        border-radius: 12px;
        padding: 12px;
        box-sizing: border-box;
        box-shadow: 0 0 6px 0 #ddd;
        margin-bottom: 12px;

        .user-img {
            display: flex;
            width: 60px;
            height: 60px;
            border-radius: 12px;
            border: 1px solid #888;
        }

        .user-info {
            flex: 1;
            font-size: 12px;
            padding-left: 12px;
            box-sizing: border-box;

            .info-row {
                display: flex;
                flex-direction: row;
                align-items: center;

                .info-row-key {
                    white-space: nowrap;
                }

                .info-row-val {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: space-between;

                    .frequency-wrap {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        font-size: 12px;

                        &>:nth-child(2) {
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }

        }
    }

    .modal-tip {
        padding: 12px 0;
        color: #f00;
    }