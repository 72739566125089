.license-plate-node {
    display: inline-block;
    padding: 2px 8px 2px 12px;
    white-space: nowrap;
    background: #003fc2;
    text-align: center;
    line-height: 26px;
    color: hsl(0, 0%, 84%);
    font-size: 12px;
    border-radius: 4px;
    letter-spacing: 2px;
    font-weight: 400;
    text-align: center;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        bottom: 2px;
        right: 2px;
        border: 1px solid #ddd;
        z-index: 10;
        border-radius: 4px;
        margin: auto;
    }

    &.electric {
        color: #090a06;
        background: linear-gradient(to bottom, #d3cfcd 0%, #4bc876 100%);

        &::after {
            border-color: #090a06;
        }
    }

}