.table-wrap {

    .table-column-action-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 12px;

        .ant-divider {
            &:nth-child(1) {
                display: none;
            }
        }

        .table-action-node {
            color: var(--theme-color);
            cursor: pointer;
        }
    }
}

/** 表格头部操作项 **/
.table-head-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .table-head-left-node {
        display: flex;
        margin-left: -4px;

        .table-head-action {
            padding: 0 4px;
        }
    }

    .table-head-right-node {
        display: flex;
        margin-right: -4px;

        .table-head-action {
            padding: 0 4px;
        }
    }
}